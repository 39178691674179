// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

*{
    outline: none;
}
th{
    white-space: nowrap;
}
td{
    white-space: nowrap;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid;
    color: #2eb85c;
    background-color: #fff;
    border-color: #d8dbe0;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2eb85c;
    border-color: #2eb85c;
}
label{
    font-size: 15px;
    font-weight: 500;
    color:#838383 !important

}

.c-datatable-filter{
 margin-left: 5px ;
.mfe-2{
    font-size: 15px;
    font-weight: 500;
    color: #4baf5d;  
}
.form-control{
    border-radius: 10px !important;
}
}

.h3{
 font-size: 26px;
 font-weight: 500;
 color: #000000;
}
.c-sidebar .c-sidebar-brand {
    color: #fff;
     background:transparent; 
}
.c-sidebar-nav-item{
.c-sidebar-nav-link{
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
    margin-right: 15px;
    color: #fff !important;
   &:hover{
        color: #4baf5d !important;
    background: #fff !important;
    border-radius: 10px;
    }
}
}

