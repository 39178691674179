// Here you can add other styles

// .chartjs-render-monitor {
//     display: block;
//     width: 623px !important;
//     height: 299px !important;
// }

.w-100px {
    width: 100px;
}
.pagination {
    justify-content: right !important;
}
